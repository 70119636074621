export default function Privacy(props) {

    return (
        <div className="p-2">
            <div className="bg-indigo-900 px-8 py-10 rounded-t-lg text-white">
                <h1 className="text-3xl mb-2">Privacy Policy</h1>
                <span>Last modified: December 4, 2021</span>
            </div>

            <div className="p-8 bg-white rounded-b-lg text-gray-600">
                <div class="mb-5">
                    <div className="mb-2">
                        <h3 className="text-2xl">Handling Payments</h3>
                    </div>
                    <div className="mb-1">
                        <h3 className="text-xl">RevenueCat</h3>
                    </div>
                    <p>
                    RevenueCat is a payment service provided by RevenueCat, Inc. The service allows the Owner to monitor and analyze the User and their purchase history and can be used to keep track of User behavior.
    Personal Data processed: Usage Data.
                    </p>
                </div>
                <div className="mb-5">
                    <div>
                        <h3 className="text-2xl mb-2">Data Collected</h3>
                    </div>
                    <div>
                        <h3 className="text-xl mb-1">Purchase History</h3>
                    </div>
                    <p className="mb-2">
                        <strong>Analytics: </strong>
                        Using data to evaluate user behavior, including to understand the effectiveness of existing product features, plan new features, or meaasure audience size or characteristics.
                    </p>
                    <p>
                        <strong>App Functionality: </strong>
                        Such as to authenticate the user, enable features, prevent fraud, implement security measures, ensure server up-time, minimize app crashes, improve scalability and performance, or perform customer support.
                    </p>
                </div>
                <div className="mb-5">
                    <div>
                        <h3 className="text-2xl mb-2">Owner and Data Controller</h3>
                    </div>
                    <p>
                        Owner contact email: info@timerapp.maxantonov.tech
                    </p>
                </div>
            </div>
        </div>
    );
}